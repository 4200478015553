@import '~antd/dist/antd.css';
@import 'assets/fonts/fonts.css';

.header {
    background: #fff !important;
    padding: 0px 30px !important;
    height: 67px !important;
}

.logo {
    margin-left: auto;
    margin-right: auto;
    width: 15em;
}

label {
    font-family: Raleway-SemiBold;
    color: rgb(112, 116, 120) !important;
}

.ant-spin {
    color: #ff6a14;
}

.ant-spin-nested-loading > div > .ant-spin {
    position: fixed !important;
    max-height: none !important;
}

.ant-spin-dot .ant-spin-dot-item {
    background-color: #ff6a14;
}