@font-face {
    font-family: 'Raleway';
    src: local('Raleway-Regular.ttf'), url('./Raleway/Raleway-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway-Bold.ttf'), url('./Raleway/Raleway-Medium.ttf');
}

@font-face {
    font-family: 'Raleway-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway-Bold.ttf'), url('./Raleway/Raleway-Bold.ttf');
}

@font-face {
    font-family: 'Raleway-SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway-SemiBold.ttf'), url('./Raleway/Raleway-SemiBold.ttf');
}

@font-face {
    font-family: 'Neris-Light';
    font-size: normal;
    font-weight: normal;
    src: local('Neris-Light.otf'), url('./Neris/Neris-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Neris-SemiBold';
    font-size: normal;
    font-weight: normal;
    src: local('Neris-SemiBold.otf'), url('./Neris/Neris-SemiBold.otf') format('opentype');
}
