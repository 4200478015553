.content {
    text-align: justify;
}

.content div.topic {
    padding: 10px;
}

.content div.topic div.topic-header {
    margin-top: 5px;
    margin-bottom: 15px;
    font-weight: bold;
}

.content div.topic div.topic-content {
    padding: 5px 1px;
}

.content div.topic div.topic-content div.topic-item {
    padding: 5px 2px;
}

.content div.topic div.topic-content div.topic-item ul {
    list-style-type: none;
    padding-left: 20px;
}

.content div.topic div.topic-content div.topic-item ul li {
    margin: 10px 5px;
}

.register {
    width: 100%;
    color: #fff !important;
    background-color: #e65235 !important;
    border-color: #e65235 !important;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12) !important;
    box-shadow: 0 2px 0 rgba(0,0,0,.045) !important;
}

.term {
    text-align: center;
}
